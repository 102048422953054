import ChooseUs from "./ChooseUs";
import ComingSoon from "./ComingSoon";

const Home = () => {
  return (
    <>
      <img src="/logistics.gif" alt="LOGISTICS" className="h-auto w-auto" />
      <ChooseUs />
      <ComingSoon />
    </>
  );
};

export default Home;
