import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-[100dvh] ">
      <Header />
      <main className="flex w-full md:px-6 px-2 flex-col flex-grow  mx-auto">
        <section className="h-full flex flex-col flex-grow ">
          <div className="w-full py-3 md:py-2  mx-auto  flex flex-col flex-grow">
            {children}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
