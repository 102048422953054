import { HiMail, HiPhone } from "react-icons/hi";
const Footer = () => {
  return (
    <footer className="md:flex header-background">
      <div
        className={`w-full py-2 md:px-10 mx-auto pt-3 flex-row md:grid grid-cols-2 gap-10`}
      >
        <div className="text-secondary">
            
          <img src="/logo.png" alt="LOGO" className="w-56" />
          <div>We promise <p className="text-accent inline">C</p>o-ordination, <p className="text-accent inline">E</p>xecution and <p className="text-accent inline">O</p>peration.</div>
        </div>
        <div className="text-center text-secondary flex-col">
          <div className="flex justify-center md:justify-start">
            <div className="hidden md:block w-1/2"></div>
            <p className="text-lg">Contact Us</p>
          </div>
          <div className="flex justify-center md:justify-start">
            <div className="hidden md:block w-1/2"></div>
            <div className="flex flex-col justify-start">
              <div className="flex items-center">
                <HiMail className="mr-1" /> roadsceo@gmail.com
              </div>
              <div className="flex items-center">
                <HiPhone className="mr-1" /> +91 8149996766
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
