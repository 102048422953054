import { Navbar } from "flowbite-react";
import Logo from "./Logo";

const Header = () => {
  return (
    <header className="header-background !w-full">
      <div className="w-full mx-auto flex flex-wrap  flex-col sm:flex-row  items-center">
        <Navbar
          fluid
          className="!w-full !bg-transparent pb-1 pt-0"
        >
          <div className="flex-none md:mr-2">
            <Logo />
          </div>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
