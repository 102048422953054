import React from "react";
import { FaPiggyBank, FaWarehouse } from "react-icons/fa";
import { HiCheckCircle, HiOutlineClock } from "react-icons/hi";

const ChooseUsOptions = ({ icon, label, description }) => {
  return (
    <div className="w-full md:w-1/2 flex mt-2">
      <div className="m-2">{icon}</div>
      <div className="w-3/4">
        <p className="text-lg text-primary">{label}</p>
        <p className="text-gray">{description}</p>
      </div>
    </div>
  );
};

const ChooseUs = () => {
  const options = [
    {
      label: "Reduces Cost",
      icon: <FaPiggyBank className="text-primary" size={40} />,
      description:
        "Reduce shipping costs with Roads-CEO. We use innovative technology and data-driven processes to optimize routes, streamline operations, and minimize waste, resulting in cost-effective and sustainable logistics solutions for your business.",
    },
    {
      label: "On-time delivery",
      icon: <HiOutlineClock className="text-primary" size={40} />,
      description:
        "Roads-CEO strives for 100% on-time delivery, ensuring our customers receive their shipments within the agreed timeframe. With advanced technology, experienced staff and efficient processes, we deliver reliable and trustworthy logistics solutions.",
    },
    {
      label: "Order Fulfillment",
      icon: <HiCheckCircle className="text-primary" size={40} />,
      description:
        "Efficient and reliable order fulfillment is critical for your business success. Roads-CEO provides end-to-end order fulfillment services, from receiving and processing orders to delivery and returns. With our expertise, we ensure fast and accurate order fulfillment for your customers.",
    },
    {
      label: "Warehouse",
      icon: <FaWarehouse className="text-primary" size={40} />,
      description:
        "Roads-CEO offers secure and efficient warehousing solutions, from receiving and storing goods to order fulfillment and distribution. We provide a safe and organized environment to ensure the proper handling and storage of your products",
    },
  ];
  return (
    <section>
      <div className="flex-row my-1">
        <div className="text-center text-xl md:text-3xl font-bold text-primary">
          Why Choose Us?
        </div>
        <div className="my-1 text-lg md:text-xl text-center text-primary">
          Find Reason To Choose Us As Your Freight Partner
        </div>
        <div></div>
        <div className="flex flex-wrap">
          {options.map((option) => (
            <ChooseUsOptions
              key={option.label}
              label={option.label}
              description={option.description}
              icon={option.icon}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;
