import React from "react";

const ComingSoon = () => {
  return (
    <div className="text-primary flex-row text-center m-2 items-center justify-center">
      <h1 className="text-3xl font-bold">We're Coming Soon</h1>
      <p className="text-gray-700">Stay tuned for your new freight partner.</p>
    </div>
  );
};

export default ComingSoon;
